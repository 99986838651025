<template>
    <div class="pb-4 overflow slide-in mx-auto">
        <div class="bg-white border border-gray-300">
            <notify-ui-new :timer="5000" :notify="notify"/>
            <header-ui :title="title"/>
            <div class="px-12 py-4">
                <image-uploader initials="Add Photo*" :data="{ photo: addEnrollee.base64ImageString }" @imageUpload="imageChange"/>
            </div>

            <div class="rounded flex flex-col content-between p-12">
                <div class="grid grid-cols-3 gap-4">
                    <input-ui-new :value="addEnrollee.lastname"
                        label="Surname *"
                        placeholder="Surname"
                        @input="event => addEnrollee.lastname = event.target.value"/>

                    <input-ui-new :value="addEnrollee.firstname"
                        label="First Name *"
                        placeholder="First Name"
                        @input="event => addEnrollee.firstname = event.target.value"/>

                    <input-ui-new :value="addEnrollee.othername"
                        label="Othername"
                        placeholder="Other Name"
                        @input="event => addEnrollee.othername = event.target.value"/>
                </div>

                <div class="grid grid-cols-1 gap-4">
                    <input-ui-new :value="addEnrollee.address"
                        label="Address"
                        placeholder="Address"
                        @input="event => addEnrollee.address = event.target.value"/>
                </div>

                <div class="grid grid-cols-3 gap-4">
                    <input-ui-new :value="addEnrollee.phone"
                        label="Phone Number *"
                        placeholder="Phone Number"
                        @input="event => addEnrollee.phone = event.target.value"/>

                    <input-ui-new :value="addEnrollee.staff_number"
                        label="Staff Number *"
                        placeholder="Staff Number"
                        @input="event => addEnrollee.staff_number = event.target.value"/>

                    <input-ui-new :value="addEnrollee.occupation"
                        label="Occupation"
                        placeholder="Occupation"
                        @input="event => addEnrollee.occupation = event.target.value"/>
                </div>

                <div class="grid grid-cols-3 gap-4">
                    <input-ui-new :value="addEnrollee.designation"
                        label="Designation"
                        placeholder="Designation"
                        @input="event => addEnrollee.designation = event.target.value"/>

                    <input-ui-new :value="addEnrollee.email"
                        label="Email Address" type="email"
                        placeholder="Email Address"
                        @input="event => addEnrollee.email = event.target.value"/>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="sex">
                            Sex *
                        </label>
                        <dropdown-ui-new :returnId="true" :options="sexOptions" @selected="event => addEnrollee.sex = event"
                            :value="addEnrollee.sex"/>
                    </div>
                </div>

                <div class="grid grid-cols-3 gap-4">
                    <input-ui-new :value="addEnrollee.birthdate"
                        label="Date Of Birth *" type="date"
                        placeholder="Date Of Birth" :max="maxDate"
                        @input="event => addEnrollee.birthdate = event.target.value"/>
                    
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="state">
                            State
                        </label>
                        <state-select @selected="event => addEnrollee.state = event" :value="addEnrollee.state"
                            searchable/>
                    </div>

                    <div class="mb-4" v-if="addEnrollee.state">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="lga">
                            Local Government
                        </label>
                        <state-division-select @selected="event => addEnrollee.lga = event" :value="addEnrollee.lga"
                            searchable :state-code="addEnrollee.state.code"/>
                    </div>
                </div>
            </div>

            <div class="mt-4 bg-white border border-gray-300">
                <header-ui title="Hospital Details"/>

                <div class="rounded flex flex-col content-between p-12">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="sex">
                                Plan *
                            </label>
                            <dropdown-ui-new :returnId="true" :options="plans" @selected="event => addEnrollee.hmo_plan_id = event"
                                :value="addEnrollee.hmo_plan_id"/>
                        </div>

                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="state">
                                State
                            </label>
                            <state-select @selected="event => addEnrollee.hospital_state = event" :value="addEnrollee.hospital_state"
                                searchable />
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-4">
                        <div class="mb-4" v-if="addEnrollee.hospital_state">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="lga">
                                Local Government
                            </label>
                            <state-division-select @selected="event => addEnrollee.hospital_lga = event" :state-code="addEnrollee.hospital_state.code" searchable
                                :value="addEnrollee.hospital_lga"/>
                        </div>

                        <div class="mb-4" v-if="addEnrollee.hospital_lga">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="provider">
                                Hospital
                            </label>
                            <provider-select ready searchable return-id @selected-provider="value=> addEnrollee.hospital_id = value"
                                :filters="providerSelectFilters" :value="addEnrollee.hospital_id"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4 bg-white border border-gray-300">
            <header-ui title="Medical Details"/>

            <div class="rounded flex flex-col content-between p-12">
                <div class="grid grid-cols-1 gap-4">
                    <input-ui-new :value="addEnrollee.allergies"
                        label="Allergies"
                        placeholder="Enter Allergies"
                        @input="event => addEnrollee.allergies = event.target.value"/>
                </div>

                <div class="grid grid-cols-1 gap-4">
                    <input-ui-new :value="addEnrollee.others"
                        label="State any other relevant information you would like us to have concerning your health"
                        placeholder="Other Relevant Information"
                        @input="event => addEnrollee.others = event.target.value"/>
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="blood_group">
                            Blood Group
                        </label>
                        <dropdown-ui-new :options="bloodGroups" @selected="event => addEnrollee.blood_group = event"
                            :value="addEnrollee.blood_group"/>
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="genotype">
                            Genotype
                        </label>
                        <dropdown-ui-new :options="genotypes" @selected="event => addEnrollee.genotype = event"
                            :value="addEnrollee.genotype"/>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-4">
                    <input-ui-new :value="addEnrollee.surgical_history" class="col-span-3"
                        label="Surgical History"
                        placeholder="Surgical History"
                        @input="event => addEnrollee.surgical_history = event.target.value"/>
                </div>

                <div>
                    <fieldset>
                        <legend>
                            Do you or have you ever suffered from any of the following
                            ailments? If so, please indicate:
                        </legend>
                        <br/>

                        <div v-for="(ailment, index) in ailments" :key="index">
                            <input :id="ailment.name" :value="ailment.name"
                                type="checkbox" @change="ailmentSelected($event, ailment)"/>
                            <label class="ml-2">{{ ailment.name }}</label>
                            <div v-if="ailment.show" class="grid grid-col-2 p-3">
                                <label class="mr-4">Start Date</label>
                                <input v-model="ailment.start" type="date"/>
                            </div>
                            <div v-if="ailment.show" class="grid grid-col-2  p-3">
                                <label class="mr-4">End Date</label>
                                <input v-model="ailment.end" type="date"/>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>

        <div v-if="!addEnrollee.parent_id" class="mt-4 bg-white border border-gray-300">
            <header-ui title="Dependant"/>
            <dependant-ui-new :list-of-dependants="listOfDependants" @notify="event => notify = event"/> 
        </div>

        <form class="mt-4" novalidate @submit.prevent="onSubmit">
            <div class="grid grid-cols-6 gap-5 mt-8">
                <div class="col-start-5">
                    <button class="bg-transparent hover:bg-blue-500
                        text-blue-700 font-semibold hover:text-white
                        py-2 px-4 border border-blue-500
                        hover:border-transparent rounded">
                        Cancel
                    </button>
                </div>

                <div class="col-end-8">
                    <button class="bg-blue-500 hover:bg-blue-700
                        text-white font-bold py-2 px-4 rounded"
                        type="submit">
                        {{ title }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import PlanService from "@/services/plan/plan-service";
import EnrolleeService from "@/services/enrollee/enrollee-service";
import HeaderUi from "@/shared/ui/Header-ui.vue";
import ImageUploader from "@/shared/ui/image-uploader.vue";
import InputUiNew from "@/shared/ui/InputUiNew.vue";
import Enrollee from "@/models/Enrollee";
import StateSelect from "@/shared/components/StateSelect.vue";
import StateDivisionSelect from "@/shared/components/StateDivisionSelect.vue";
import DropdownUiNew from "@/shared/ui/DropdownUiNew.vue";
import moment from 'moment';
import ProviderSelect from "@/shared/components/ProviderSelect";
import DependantUiNew from "@/shared/ui/DependantUiNew.vue";
import NotifyUiNew from "@/shared/ui/NotifyUiNew.vue";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
    name: 'AddNew',
    components: {
        HeaderUi, ImageUploader, InputUiNew, 
        StateSelect, DropdownUiNew, ProviderSelect, 
        DependantUiNew, NotifyUiNew, StateDivisionSelect
        },
    props: {
        id: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            planService: new PlanService(),
            addEnrollee: new Enrollee(),
            enrolleeService: new EnrolleeService(),
            router: useRouter(),
            sexOptions: [
                { id: 'M', name: "Male" },
                { id: "F", name: "Female" },
                { id: "O", name: "Other" }
            ],
            bloodGroups: ["A","A+","A-","B","B+","B-","AB","AB+","AB-","O","O+","O-"],
            genotypes: ["AA", "AB","AC","AO","AS","BB","BO","OO","SC","SS","AC (uncommon)"],
            ailments: [
                {name: "Hypertension", show: false, start: null, end: null},
                {name: "Diabetes Mellitus", show: false, start: null, end: null},
                {name: "Duodenal Ulcer", show: false, start: null, end: null},
                {name: "Sickle Cell Disease", show: false, start: null, end: null},
                {name: "Glaucoma", show: false, start: null, end: null},
                {name: "Heart Disease", show: false, start: null, end: null},
                {name: "Kidney Disease", show: false, start: null, end: null},
                {name: "Epilepsy", show: false, start: null, end: null},
                {name: "Asthma", show: false, start: null, end: null},
                {name: "Tuberculosis", show: false, start: null, end: null},
                {name: "HIV/AIDS", show: false, start: null, end: null},
            ],
            stateDivisions: [],
            hospitalStateDivisions: [],
            plans: [],
            listOfDependants: [],
            fieldName: "",
            notify: {
                show: false,
                type: "",
                message: ""
            }
        }
    },
    created() {
        if (this.id) {
            this.getEnrollee(this.id)
        }
        this.getPlans()
    },
    computed: {
        title() {
            return this.id ? "Edit Enrollee" : "Add Enrollee"
        },
        maxDate() {
            return moment(new Date()).format("YYYY-MM-DD")
        },
        providerSelectFilters() {
            return {
                state_id: this.addEnrollee.hospital_state?.id,
                state_division_id: this.addEnrollee.hospital_lga?.id
            }
        },
        validation() {
            let { firstname, lastname, sex, birthdate, phone, hmo_plan_id, base64ImageString, staff_number  } = this.addEnrollee
            return (
                this.isNotNull(lastname, "Enrollee Surname") && this.isNotNull(firstname, "Enrollee First name") && 
                this.isNotNull(birthdate, "Enrollee Birth Date") && this.isNotNull(sex, "Enrollee Sex") && 
                this.isNotNull(phone, "Enrollee Phone Number") && this.isNotNull(hmo_plan_id, "Enrollee Plan") && 
                this.isNotNull(base64ImageString, "Enrollee Image") && this.isNotNull(staff_number, "Enrollee Staff Number")
            )
        }
    },
    methods: {
        async getEnrollee(id) {
            let loader = this.$loading.show()
            try {
                const { data } = await this.enrolleeService.getEnrollee(id)
                this.addEnrollee = new Enrollee(data)
                this.addEnrollee.base64ImageString = this.addEnrollee.photo
                this.listOfDependants = this.addEnrollee.dependants
            } catch (error) {
                this.notify = {
                    type: 'error',
                    message: "Unable to get enrollee details",
                    show: true
                }
                console.error(error)
            } finally {
                loader.hide()
            }
        },
        onSubmit() {
            this.id ? this.edit() : this.submit()
        },
        imageChange(value) {
            if(value){
                const myImage = new Image();
                myImage.src = value;
                const _this = this;
                myImage.onload = function() {
                    if(this.width > 700 && this.height > 700){
                        Swal.fire({
                            icon: "error",
                            title: "Image dimension too large",
                            text: "Images resolution should be within 700 * 700px",
                        })
                    }else {
                        _this.addEnrollee.base64ImageString = value
                    }
                };
            }else {
                this.addEnrollee.base64ImageString = null
            }
        },
        async getPlans() {
            try {
                const { data: { data: plans } } = await this.planService.getPlans()
                this.plans = plans
            } catch (error) {
                console.error(error)
            }
        },
        async submit() {
            if (!this.validation) {
                this.notify = {
                    type: "error",
                    message: `Fill ${this.fieldName} column`,
                    show: true
                }
                return false
            }

            this.addEnrollee.dependants = this.listOfDependants
            this.addEnrollee.status = "PP"
            this.addEnrollee.hospital_lga = this.addEnrollee.hospital_lga?.name ?? this.addEnrollee.hospital_lga
            this.addEnrollee.hospital_state = this.addEnrollee.hospital_state?.name ?? this.addEnrollee.hospital_state
            this.addEnrollee.state = this.addEnrollee.state?.name ?? this.addEnrollee.state
            this.addEnrollee.lga = this.addEnrollee.lga?.name ?? this.addEnrollee.lga

            let loader = this.$loading.show()
            try {
                const { data } = await this.enrolleeService.addEnrollee(this.addEnrollee)

                if (data.dependantError) {
                    loader.hide()
                        await Swal.fire({
                            icon: "error",
                            title: "Unable to save some Dependants",
                            text: data.dependantError,
                        })
                } else {
                    loader.hide()
                    this.notify = {
                        type: "success",
                        message: "Enrollee Added",
                        show: true
                    }
                    this.addEnrollee.clear()
                    this.addEnrollee.base64ImageString = null

                    await this.router.push("enrollees")
                }
            } catch (error) {
                loader.hide()
                this.notify = {
                    type: 'error',
                    message: "Enrollee Not Added",
                    show: true
                }
                console.error(error)
            }
        },
        async edit() {
            this.addEnrollee.dependants = this.listOfDependants
            this.addEnrollee.hospital_lga = this.addEnrollee.hospital_lga?.name ?? this.addEnrollee.hospital_lga
            this.addEnrollee.hospital_state = this.addEnrollee.hospital_state?.name ?? this.addEnrollee.hospital_state
            this.addEnrollee.state = this.addEnrollee.state?.name ?? this.addEnrollee.state
            this.addEnrollee.lga = this.addEnrollee.lga?.name ?? this.addEnrollee.lga

            let loader = this.$loading.show()
            try {
                const { data } = await this.enrolleeService.editEnrollee(this.addEnrollee)

                if (data.dependantError) {
                    this.notify = {
                        type: "error",
                        message: data.dependantError,
                        show: true
                    }
                } else {
                    this.notify = {
                        type: "success",
                        message: "Enrollee Edit Complete",
                        show: true
                    }
                }
            } catch (error) {
                this.notify = {
                        type: "error",
                        message: "Enrollee Edit Failed",
                        show: true
                    }
                console.error(error)
            } finally {
                loader.hide()
            }
        },
        ailmentSelected(event, ailment) {
            ailment.show = !ailment.show
            
            if (ailment.show) {
                this.addEnrollee.medical_history.push(ailment)
            } else {
                this.addEnrollee.medical_history = this.addEnrollee.medical_history.filter(entry => entry.name != ailment.name)
            }
        },
        isNotNull(variable, name = null) {
            if ((variable ===  "") || (variable === undefined) || (variable == null)) {
                this.fieldName = name
                return false;
            }
            return true;
        }
    },
}
</script>
<style scoped>

</style>