<template>
    <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">
            {{ label }}
        </label>
        <input v-bind="$attrs"
            :value="value"
            @input="$emit('input', $event)"
            required aria-required="true"
            :max="max" :min="min"
            class="shadow border rounded w-full appearance-none py-2 px-3 
            text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            :type="type" :placeholder="placeholder"/>
    </div>
</template>
<script>
export default {
    name: "InputUiNew",
    props: {
        type: {
            default: 'text',
        },
        value: String,
        placeholder: String,
        label: String,
        max: String,
        min: String,
    },
}
</script>
<style scoped>

</style>