<template>
    <div class="rounded flex flex-col content-between p-12">
        <div class="mb-7">
            <image-uploader :data="{ photo: addDependant.base64ImageString }"
                @imageUpload="imageChange" initials="Add Photo*"/>
        </div>

        <div class="grid grid-cols-3 gap-4">
            <input-ui-new :value="addDependant.lastname"
                label="Surname *"
                placeholder="Surname"
                @input="event => addDependant.lastname = event.target.value"/>

            <input-ui-new :value="addDependant.firstname"
                label="First Name *"
                placeholder="First Name"
                @input="event => addDependant.firstname = event.target.value"/>

            <input-ui-new :value="addDependant.othername"
                label="Other Name"
                placeholder="Other Name"
                @input="event => addDependant.othername = event.target.value"/>
        </div>

        <div class="grid grid-cols-3 gap-4">
            <input-ui-new :value="addDependant.birthdate"
                label="Date Of Birth *" type="date"
                placeholder="Date Of Birth"
                @input="event => addDependant.birthdate = event.target.value"/>

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="sex">
                    Sex *
                </label>
                <dropdown-ui-new :returnId="true" :options="sexOptions" @selected="event => addDependant.sex = event"
                    :value="addDependant.sex"/>
            </div>

            <div>
                <label class="block text-gray-700 text-sm font-bold mb-2" for="relationship">
                    Relationship
                </label>
                <dropdown-ui-new :options="['spouse','child']" @selected="event => addDependant.relationship = event"
                    :value="addDependant.relationship"/>
            </div>
        </div>

        <div class="grid grid-cols-1 gap-4">
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="hospital">
                    Hospital *
                </label>
                <provider-select ready searchable return-id @selected-provider="value=> addDependant.hospital_id = value"
                    :value="addDependant.hospital_id"/>
            </div>
        </div>

        <div class="mb-4 w-full flex justify-end mt-2">
            <button class="flex gap-2 bg-white-500 text-white font-bold rounded" @click="addToDependantList">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus"
                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#114C70"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="9" cy="7" r="4" />
                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        <path d="M16 11h6m-3 -3v6" />
                    </svg>
                </div>
                <div style="color: #114c70">Add Dependent</div>
            </button>
        </div>

        <div style="white-space: nowrap; overflow: auto">
            <div class="shadow-lg m-2 rounded-full py-3 px-6"
                style="position: relative; display: inline-block; cursor: pointer;"
                v-for="(dependant, index) in listOfDependants"
                :key="index">
                {{ dependant.lastname }} {{ dependant.firstname }} 
                <div @click="applyDependant(dependant)"
                    class="absolute bg-red-500 p-1"
                    style="top: 0px; right: -4px; border-radius: 50px;">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-minus"
                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="9" cy="7" r="4" />
                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        <line x1="16" y1="11" x2="22" y2="11" />
                    </svg>
                </div>   
            </div>
        </div>

    </div>
</template>
<script>
import ProviderSelect from "@/shared/components/ProviderSelect";
import ImageUploader from "@/shared/ui/image-uploader.vue";
import DropdownUiNew from "@/shared/ui/DropdownUiNew.vue";
import InputUiNew from "@/shared/ui/InputUiNew.vue";
import HeaderUi from "@/shared/ui/Header-ui.vue";
import Dependant from "@/models/Dependant";
import Swal from "sweetalert2";

export default {
    name: 'DependantUiNew',
    props: {
        listOfDependants: {
            type: Array,
            default: []
        },
        notify: {
            type: Object,
            default: {
                type: "",
                message: "",
                show: false
            }
        }
    },
    components: { ProviderSelect, ImageUploader, DropdownUiNew, InputUiNew, HeaderUi },
    data() {
        return {
            addDependant: new Dependant(),
            sexOptions: [
                { id: 'M', name: "Male" },
                { id: "F", name: "Female" },
                { id: "O", name: "Other" }
            ],
            fieldName: "",
        }
    },
    computed: {
        validation() {
            let { firstname, lastname, sex, birthdate, hospital_id } = this.addDependant
            return (
                this.isNotNull(firstname, "Dependant First Name") && this.isNotNull(lastname, 'Dependant Surname')
                && this.isNotNull(sex, "Dependant Sex") && this.isNotNull(birthdate, "Dependant Birth date") &&
                this.isNotNull(hospital_id, "Dependant Hospital")
            )
        }
    },
    methods: {
        imageChange(value) {
            if(value){
                const myImage = new Image();
                myImage.src = value;
                const _this = this;
                myImage.onload = function() {
                    if(this.width > 700 && this.height > 700){
                        Swal.fire({
                            icon: "error",
                            title: "Image dimension too large",
                            text: "Images resolution should be within 700 * 700px",
                        })
                    }else {
                        _this.addDependant.base64ImageString = value
                    }
                };
            }else {
                this.addDependant.base64ImageString = null
            }
            // this.addDependant.base64ImageString = value
        },
        addToDependantList() {
            let tempDependant = { ...this.addDependant }
            if (this.validation) {
                this.listOfDependants.push(tempDependant)
                this.addDependant.clear()
                this.addDependant.base64ImageString = null
            } else {
                this.$emit('notify', {
                    type: 'error',
                    message: `Fill ${this.fieldName} column`,
                    show: true
                })
            }
        },
        applyDependant(dependant = null) {
            this.addDependant = Dependant.fromJson(dependant)
            this.addDependant.base64ImageString = dependant.photo ?? dependant.base64ImageString

            this.listOfDependants.splice(this.listOfDependants.indexOf(dependant), 1)
        },
        isNotNull(variable, name = null) {
            if ((variable ===  "") || (variable === undefined) || (variable == null)) {
                this.fieldName = name
                return false;
            }
            return true;
        }
    }
}
</script>
<style scoped>

</style>