<template>
    <vue-multiselect
        v-model="selectedState"
        :clear-on-select="false"
        :close-on-select="true"
        :hide-selected="true"
        :label="label"
        :loading="isLoading"
        :options="states"
        :options-limit="optionsLimit"
        :placeholder="placeholder"
        :searchable="searchable"
        open-direction="bottom"
        track-by="id"
        @select="onSelect"
        @search-change="getStates">
    </vue-multiselect>
</template>
<script>
import CountryService from "@/services/country/country-service";
import VueMultiselect from "vue-multiselect";
import { showError } from "@/shared/helpers";

export default {
    name: 'StateSelect',
    components: { VueMultiselect },
    emits: ['update:modelValue', 'selected'],
    props: {
        returnId: Boolean,
        value: {},
        label: {
            type: String,
            default: 'name'
        },
        optionsLimit: {
            type: Number,
            default: 10
        },
        placeholder: {
            type: String,
            default: 'Select State'
        },
        searchable: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            isLoading: false,
            countryService: new CountryService(),
            states: [],
            selectedState: null
        }
    },
    created() {
        this.getStates()
    },
    watch: {
        'filters': function(value) {
            this.getStates()
        },
        'value': function(value) {
            this.getValue()
        }
    },
    methods: {
        onSelect(state) {
            let filteredState = null
            if (this.returnId) {
                filteredState = state.id
            } else {
                filteredState = state
            }
            this.$emit('selected', filteredState)
        },
        async getStates(search) {
            this.isLoading = true
            try {
                const { data } = await this.countryService.getStates("NGA", { ...this.filters, search })
                this.states = data
                this.getValue()
            } catch (error) {
                showError("Error", error)
            } finally {
                this.isLoading = false
            }
            
        },
        getValue() {
            if (!this.value) {
                this.selectedState = null
            } else {
                this.selectedState = this.states.find(state => {
                    if (typeof this.value === 'object') {
                        return state.id === this.value?.id
                    } else {
                        return (state.id === this.value) || (state.name === this.value)
                    }
                })
            }
            if (this.selectedState) {
                this.$emit('selected', this.selectedState)
            }
        },
    },
}
</script>