<template>
  <VueMultiselect
      v-model="selectedProvider"
      :clear-on-select="false"
      :close-on-select="true"
      :hide-selected="true"
      :label="label"
      :loading="isLoading"
      :options="options"
      :options-limit="optionsLimit"
      :placeholder="placeholder"
      :searchable="searchable"
      open-direction="bottom"
      track-by="id"
      @select="onSelect"
      @search-change="searchProviders"
  >
  </VueMultiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import {Axios} from "@/shared/axios";
import {showError} from "@/shared/helpers";

export default {
  name: "ProviderSelect",
  components: {VueMultiselect},
  emits: ['update:modelValue', 'selectedProvider'],
  props: {
    optionsLimit: {
      type: Number,
      default: 10
    },
    searchable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'name'
    },
    placeholder: {
      type: String,
      default: 'Select Provider'
    },
    returnId: Boolean,
    returnName: Boolean,
    ready: Boolean,
    filters: Object,
    value: {},
  },

  data() {
    return {
      isLoading: false,
      options: [],
      selectedProvider: null
    }
  },
  created() {
    if (this.ready) {
      this.searchProviders();
    }
  },

  watch: {
    'filters': function (value) {
      this.searchProviders()
    },
    'value': function (value) {
      this.getValue()
    }
  },

  methods: {
    async searchProviders(search) {
      this.isLoading = true;
      try {
        const { data } = await Axios.get(`/providers`, { params: { search, ...this.filters }})
        this.options = data
        this.getValue()
      } catch (error) {
        showError("Error", error)
      } finally {
        this.isLoading = false;
      }
    },

    onSelect: function (provider) {
      let filteredProvider = null;
      if (this.returnId) {
        filteredProvider = provider.id;
      } else if (this.returnName) {
        filteredProvider = provider.name;
      }
      else {
        filteredProvider = provider;
      }
      this.$emit('selectedProvider', filteredProvider);
    },

    getValue() {
      if (!this.value) {
        this.selectedProvider = null
      } else {
        this.selectedProvider = this.options.find(provider => {
          if (typeof this.value === 'object') {
            return provider.id === this.value.id
          } else {
            return (provider.id === this.value) || (provider.name === this.value)
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>