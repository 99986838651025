<template>
    <div v-if="notify.show" class="fixed join-it shadow-lg grid grid-cols-6
      gap-4 w-100 p-2 m-2 rounded-lg flex font-mono self-center
      bg-opacity-75" :class="getColor" style="z-index: 99; top: 4rem; right: 4rem">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-circle"
                width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
                fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <line x1="12" y1="8" x2="12.01" y2="8" />
                <polyline points="11 12 12 12 12 16 13 16" />
            </svg>
        </div>
        <div class="text-white items-center flex col-start-2 col-span-4">
            {{ notify.message }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'NotifyUiNew',
    props: {
        timer: {
            type: Number,
            default: 4000
        },
        notify: {
            type: Object,
            default: {
                type: "",
                message: "",
                show: false
            },
        }
    },
    watch: {
        'notify': function(newValue) {
            if (newValue.show) {
                setTimeout(() => {
                    this.notify.show = false
                }, this.timer)
            }
        }
    },
    computed: {
        getColor() {
            switch(this.notify.type.toLowerCase()) {
                case "success": {
                    return "bg-green-500"
                }
                case "error": {
                    return "bg-red-500"
                }
                default: {
                    return "bg-blue-500"
                }
            }
        }
    }
}
</script>
<style scoped>

</style>