<template>
  <div class="flex justify-between px-5 py-4" style="height: 10%">
    <div class="w-full text-primary font-semibold py-1 flex items-center">
      <div class="flex items-center font-extrabold">
        <span>{{ title }}</span>
      </div>
    </div>
  </div>
  <hr />
</template>

<script>
/**
 * A shared component for creating headers within forms
 * @example
 * <header-ui name="title"/>
 */
export default {
  props: {
    /**
     * The text within the header
     * @values any
     */
    title: String,
  },
  setup(props) {
    const title = props.title;
    return {
      title,
    };
  },
};
</script>

<style scoped>
</style>