import {
    Axios
} from "@/shared/axios"
export default class CountryService {
    constructor() {
    }

    /**
     * Retrieves states in a country
     *
     * @param {String} countryCode - A code representing the country
     * @return {State} A array of states
     *
     * @example
     *
     *     await getStates()
     */
    getStates = async (countryCode, params = {}) => {
        try {
            return await Axios.get("countries/" + countryCode + "/states", { params });
        } catch (error) {
            console.log(error);
            return {
                message: "Error Occured",
                status: 500
            }
        }
    }
    getStateDivisions = async (stateCode, params = {}) => {
        try {
            return await Axios.get("countries/states/" + stateCode + "/divisions", { params });
        }
        catch (err) {
            console.log(error);
            return {
                message: "Error Occured",
                status: 500
            }
        }
    }
}