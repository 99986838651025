'use strict';
class Enrollee {
    /**
     * @typedef {import('./Enrollee').default} Enrollee
     */
    constructor(data = {}) {
        this.id = data.id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.othername = data.middle_name;
        this.phone = data.phone;
        this.phone2 = data.phone2;
        this.email = data.email;
        this.sex = data.sex;
        this.status = data.status ?? "PP";
        this.address = data.address;
        this.birthdate = this.formatDate(data.birthdate ?? "");
        this.dependants = data.dependants;
        this.status = data.status;
        this.state = data.state;
        this.lga = data.lga;
        this.hospital_state = data.hospital_state,
        this.hospital_lga = data.hospital_lga,
        this.base64ImageString = data.base64ImageString
        this.photo = data.photo;
        this.hospital_id = data.primary_provider_id;
        this.hmo_plan_id = data.hmo_plan_id;
        this.hmo_client_id = data.hmo_client_id;
        this.staff_number = data.staff_number;
        this.blood_group = data.blood_group;
        this.genotype = data.genotype;
        this.allergies = data.allergies;
        this.others = data.others;
        this.surgical_history = data.surgical_history;
        this.medical_history = data.medical_history ?? [];
        //Object.seal(this);
    }

    /**
     * Factory for creating a new Object from json parameter
     *
     * @param {Object} json - A json param
     * @return {Enrollee}
     *
     * @example
     * Enrollee.fromJson({})
     *     
     */
    static fromJson(json) {
        return Object.assign(new Enrollee(), json);
    }

    /**
     * Factory for creating a Json Object from Enrollee Object
     *
     * @param {Enrollee} enrollee - An enrollee object
     * @return {Object}
     *
     * @example
     * new Enrollee() || Enrollee.fromJson({})
     * Enrollee.toJson(enrollee);
     *     
     */
    static toJson(enrollee) {
        return JSON.stringify(enrollee);
    }

    formatDate(date) {
        const dateT = date.split("T")[0];
        console.log(date);
        return dateT;
    }

    /**
     * Clears the content of the object
     *
     * @param null
     * @return void
     *
     * @example
     * new Enrollee().clear();
     *     
     */

    clear() {
        this.firstname = "";
        this.lastname = "";
        this.phone = "";
        this.phone2 = "";
        this.email = "";
        this.sex = "";
        this.status = "";
        this.address = "";
        this.birthdate = "";
        this.status = "";
        this.photo="";
        this.dependants = []
        this.base64ImageString = "";
        this.hmo_plan_id = "";
        this.hmo_client_id = "";
        this.hospital_id = "";
        this.staffNumber = "";
        this.bloodGroup = "";
        this.genotype = "";
        this.medical_history = [];
    }
}

export default Enrollee