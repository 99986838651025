'use strict';
class Dependant {
    /**
     * @typedef {import('./Dependant').default} Dependant
     */
    constructor(data = {}) {
        this.id = data.id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.othername = data.othername;
        this.sex = data.sex;
        this.birthdate = this.formatDate(data.birthdate ?? "");
        this.relationship = data.parent_relationship;
        this.hospital_id = data.primary_provider_id;
        this.base64ImageString = data.base64ImageString;
        this.photo = data.photo;
        //Object.seal(this);
    }

    /**
     * Factory for creating a new Object from json parameter
     *
     * @param {Object} json - A json param
     * @return {Dependant}
     *
     * @example
     * dependant.fromJson({})
     *     
     */
    static fromJson(json) {
        let dependant = Object.assign(new Dependant(), json)
        dependant.birthdate = dependant.birthdate.split("T")[0] ?? ""
        dependant.hospital_id = dependant.primary_provider_id ?? dependant.hospital_id
        dependant.relationship = dependant.parent_relationship ?? dependant.relationship
        return dependant;
    }

    /**
     * Factory for creating a Json Object from dependant Object
     *
     * @param {Dependant} dependant - An dependant object
     * @return {Object}
     *
     * @example
     * new Dependant() || Dependant.fromJson({})
     * Dependant.toJson(depndant);
     *     
     */
    static toJson(dependant) {
        return JSON.stringify(dependant);
    }

    formatDate(date) {
        return date.split("T")[0];
    }

    /**
     * Clears the content of the object
     *
     * @param null
     * @return void
     *
     * @example
     * new Depndant().clear();
     *     
     */

    clear() {
        this.firstname = null;
        this.lastname = null;
        this.sex = null;
        this.birthdate = null;
        this.othername = null;
        this.relationship = null;
        this.hospital_id = null;
    }
}

export default Dependant;