<template>
    <div class="relative w-full text-left curacel-dropvsv21131vdahgasada">
        <div class="curacel-dropvsv21131vdahgasada">
            <button @click="toggleMenu"
                class="curacel-dropvsv21131vdahgasada flex 
                    justify-start rounded-md w-full
                    border border-gray-300 shadow-sm
                    text-left px-4 py-2 bg-white
                    text-sm font-medium text-gray-700
                    hover:bg-gray-50 focus:outline-none
                    focus:ring-2 focus:ring-offset-2
                    focus:ring-offset-gray-100
                    focus:ring-indigo-500"
                    id="menu-button" aria-expanded="true"
                    aria-haspopup="true">
                <div class="w-full curacel-dropvsv21131vdahgasada">
                    {{ optionDisplay }}
                </div>
                <div class="curacel-dropvsv21131vdahgasada">
                    <svg class="-mr-1 ml-2 h-5 w-5 curacel-dropvsv21131vdahgasada"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"/>
                    </svg>
                </div>
            </button>
        </div>

        <div v-show="isOpen" class="curacel-dropvsv21131vdahgasada
            origin-top-right absolute w-full left-0 mt-2
            w-56 rounded-md shadow-lg bg-white ring-1 ring-black 
            ring-opacity-5 focus:outline-none" style="z-index: 100"
            role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
            tabindex="-1">
            <div class="py-1 curacel-dropvsv21131vdahgasada"
                role="none" style="max-height: 10rem; overflow: auto;">
                <div class="curacel-dropvsv21131vdahgasada
                    cursor-pointer text-gray-700 block
                    px-4 py-2 text-sm"
                    v-for="(option, index) in options" :key="index">
                    <div class="curacel-dropvsv21131vdahgasada"
                        @click="handleChange(option)">
                        {{ option?.name || option }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "DropdownUiNew",
    props: {
        returnId: Boolean,
        options: Array,
        value: {
            required: false
        }
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        optionDisplay() {
            if (this.value && (typeof this.value === 'string')) {
                return this.value
            } else if (this.value && typeof this.value === 'number') {
                let tempValue = this.options.find(element => {
                    if (typeof element === 'object') {
                        return (element.id === this.value) || (element.name === this.value)
                    } else {
                        return element === this.value
                    }
                })
                return tempValue?.name ?? tempValue
            } else if (this.value && (typeof this.value === 'object') && this.value?.name) {
                return this.value?.name
            } else {
                return '-'
            }
        }
    },
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen
        },

        handleChange(option) {
            this.$emit('selected', this.returnId ? option?.id : option)
            this.toggleMenu()
        },
    }, 
}
</script>
<style scoped>

</style>