<template>
    <vue-multiselect
        v-model="selectedDivision"
        :clear-on-select="false"
        :close-on-select="true"
        :hide-selected="true"
        :label="label"
        :loading="isLoading"
        :options="stateDivisions"
        :options-limit="optionsLimit"
        :placeholder="placeholder"
        :searchable="searchable"
        open-direction="bottom"
        track-by="id"
        @select="onSelect"
        @search-change="getStateDivisions"/>
</template>

<script>
import CountryService from "@/services/country/country-service";
import VueMultiselect from "vue-multiselect";
import { showError } from "@/shared/helpers";

export default {
    name: 'StateDivisionSelect',
    components: { VueMultiselect },
    emits: ['update:modelValue', 'selected'],
    props: {
        returnId: Boolean,
        value: {},
        label: {
            type: String,
            default: 'name',
        },
        optionsLimit: {
            type: Number,
            default: 10,
        },
        placeholder: {
            type: String,
            default: 'Select LGA'
        },
        searchable: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
            default: {}
        },
        stateCode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedDivision: null,
            stateDivisions: [],
            countryService: new CountryService(),
            isLoading: false,
        }
    },
    created() {
        this.getStateDivisions()
    },
    watch: {
        'stateCode': function(value) {
            this.getStateDivisions()
        },
        'filters': function() {
            this.getStateDivisions()
        },
        'value': function() {
            this.getValue()
        },
        
    },
    methods: {
        async getStateDivisions(search) {
            if (!this.stateCode) {
                return false;
            }
            this.isLoading = true
            try {
                const { data } = await this.countryService.getStateDivisions(this.stateCode, { ...this.filters, search })
                this.stateDivisions = data.data
                this.getValue()
            } catch (error) {
                showError("Error", error)
            } finally {
                this.isLoading = false
            }
        },
        onSelect(stateDivision) {
            let filteredDivision = null
            if (this.returnId) {
                filteredDivision = stateDivision.id
            } else {
                filteredDivision = stateDivision
            }
            this.$emit('selected', filteredDivision)
        },
        getValue() {
            if (!this.value) {
                this.selectedDivision = null
            } else {
                this.selectedDivision = this.stateDivisions.find(division => {
                    if (typeof this.value === 'object') {
                        return division.id === this.value?.id
                    } else {
                        return (division.id === this.value) || (division.name === this.value)
                    }
                })
            }
            if (this.selectedDivision) {
                this.$emit('selected', this.selectedDivision)
            }
        },
    }
}
</script>

<style scoped>

</style>