import {Axios} from "@/shared/axios"
import Enrollee from "../../models/Enrollee";

/**
 * A Service to handle API calls for enrollee entity.
 * @class
 */
export default class EnroleeService {
    /**
     * @typedef {import('../../models/Enrollee').default} Enrollee
     */

    constructor() {

    }

    /**
     * Adds Enrollee from an Enrolee Object.
     *
     * @param {Enrollee} enrollee - An enrollee object
     * @return mixed
     *
     * @example
     *
     *     await addEnrollee(enrollee)
     */
    addEnrollee = async (enrollee) => {
        return Axios.post("hmo-client/enrollee", Enrollee.toJson(enrollee));
    }
    /**
     * Edits Enrollee with an Enrolee Object.
     *
     * @param {Enrollee} enrollee - An enrollee object
     * @return mixed
     *
     * @example
     *
     *     await editEnrollee(enrollee)
     */
    editEnrollee = (enrollee) => {
        return  Axios.put(`hmo-client/enrollee`, Enrollee.toJson(enrollee))
    }

    /**
     * Retrieves an Enrollee.
     *
     * @param {Number} enrolleeId - An enrollee Id
     * @return {Enrollee} A single enrollee object
     *
     * @example
     *
     *     await getEnrollee()
     */
    getEnrollee = (enrolleeId) => {
        return  Axios.get(`enrollees/${enrolleeId}`);
    }
}